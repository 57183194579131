<!--
 * @Descripttion: 树形
 * @Author: 彭博
 * @Date: 2020-07-23 09:28:59
 * @LastEditors: 彭博
 * @LastEditTime: 2020-08-18 10:56:13
-->
<template>
  <el-checkbox-group v-model="checkedValue" :max="$parent.config.isMulti ? 999 : 1">
    <el-checkbox
      v-for="(item, index) in showList"
      :key="index"
      class="en-transfer-panel-item"
      :label="item"
      :disabled="item.disabled"
    >
      <slot name="left-content" :node="Object.assign(item,{label:item[$parent.props.label]})" >
        {{ item[$parent.props.label] }}
      </slot>
    </el-checkbox>
  </el-checkbox-group>
</template>

<script>
export default {
  model: {
    prop: "checkedList",
    event: "throwCheckedList"
  },
  props: {
    checkedList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    treeNode() {
      return this.$refs.tree;
    },
    showList() {
      return this.filterListItem(this.$parent.keywords);
    }
  },
  data() {
    return {
      checkedValue: this.checkedList
    };
  },
  watch: {
    checkedValue: {
      handler(value) {
        this.$nextTick(() => {
          this.$emit("throwCheckedList", value);
        });
      }
    },
    checkedList: {
      handler(value) {
        this.checkedValue !== value && (this.checkedValue = value);
      }
    }
  },
  methods: {
    filterListItem(str) {
      if (!str) return this.$parent.list;
      const keyObj = this.$parent.props;
      return this.$parent.list.filter((item) => item[keyObj.label].indexOf(str) !== -1);
    }
  }
};
</script>

<style></style>
