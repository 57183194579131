<!--
 * @Descripttion: 组件只需 穿梭框组件左侧内容
 * @Author: 彭博
 * @Date: 2020-07-23 09:29:35
 * @LastEditors: pengyu
 * @LastEditTime: 2021-07-12 11:44:24
-->
<template>
  <div class="en-tree-transfer" :style="{height: height ? (height  + 'px') : 100}">
    <div class="en-transfer-panel">
      <div class="en-transfer-panel-title" v-if="config.title && config.title.length > 0 && config.showTitle !== 'false'">
        <slot name="left-title">
          <template v-if="!config.isMulti">
            {{ config.title[0] }}
          </template>
          <el-checkbox v-model="checkedAll" :indeterminate="isIndeterminate" @change="toggleCheckAll" v-else>
            {{ config.title[0] }}
          </el-checkbox>
          <template v-if="config.isMulti">
            {{ checkedList.length + " /" + allowCheckList.length }}
          </template>
        </slot>
      </div>
      <en-search
        v-if="!config.hideSearch"
        v-model="keywords"
        class="en-transfer-search-title"
        showBorder
        clearable
        suffix-icon="el-icon-search"
        :placeholder="config.placeholder"
        @search="handleFuzzySearch"
      ></en-search>
      <div class="en-keyword-search" v-show="showKeywordSearh" @click="handleKeywordSearch">
        关键字搜素：{{ keywords }}
      </div>
      <div class="en-transfer-panel-body" :class="{'with-search': config.hideSearch}">
        <components :is="leftType" ref="sonTree" v-model="checkedList" >
          <template #left-content="{ data, node }">
            <slot name="left-content" :data="data" :node="node"></slot>
          </template>
        </components>
      </div>
    </div>
  </div>
</template>

<script>
import { componentMixins } from "en-js";
import TreeLeft from "./treeLeft";
import ParallelLeft from "./parallelLeft";

export default {
  name: "OnlyLeft",
  componentName: "OnlyLeft",
  mixins: [componentMixins],
  provide() {
    return {
      transfer: this
    };
  },
  model: {
    prop: "result",
    event: "setResult"
  },
  props: {
    config: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: "tree"
    },
    list: {
      type: Array,
      default: () => []
    },
    props: {
      type: Object,
      default: () => ({})
    },
    lazy: {
      type: Boolean,
      default: true
    },
    load: {
      type: Function,
      default: () => {}
    },
    result: {
      type: Array,
      default: () => []
    },
    height: Number
  },
  components: {
    TreeLeft,
    ParallelLeft
  },
  computed: {
    leftType() {
      return this.type === "tree" ? "TreeLeft" : "ParallelLeft";
    },
    isTree() {
      return this.type === "tree";
    },
    showKeywordSearh() {
      return this.config.keywordSearch && this.keywords;
    },
    checkedList: {
      get() {
        return this.result;
      },
      set(value) {
        this.$emit("setResult", value);
        this.resetLeftCheckStatus();
      }
    }
  },
  data() {
    return {
      keywords: "",
      isIndeterminate: false,
      checkedAll: false,
      // checkedList: this.result,
      allowCheckList: [],
      dataList: this.list,
      options: []
    };
  },
  watch: {
    checkedList: {
      deep: true,
      immediate: true,
      handler(value) {
        this.$emit("setResult", value);
        this.resetLeftCheckStatus();
      }
    },
    // keywords(val) {
    //    this.isTree && this.$refs.sonTree.treeNode.filter(val);
    // },
    options() {
      this.$nextTick(() => {
        const { store } = this.$refs.sonTree.treeNode;
        const allData = store._getAllNodes();
        const allowCheckList = allData.filter((v) => !v.disabled);
        this.allowCheckList = allowCheckList;
        this.resetLeftCheckStatus();
      });
    },
    list: {
      deep: true,
      immediate: true,
      handler(value) {
        const allowCheckList = value.filter((v) => !v.disabled);
        this.allowCheckList = allowCheckList;
        this.resetLeftCheckStatus();
      }
    }
  },
  methods: {
    toggleCheckAll() {
      this.isTree ? this.toggleTreeCheckAll() : this.toggleNormalCheckAll();
    },
    toggleTreeCheckAll() {
      if (this.checkedList.length === this.allowCheckList.length) {
        this.allowCheckList.forEach((v) => {
          v.checked = false;
        });
      } else {
        this.allowCheckList.forEach((v) => {
          v.checked = true;
        });
      }
      this.$nextTick(() => {
        this.checkedList = this.$refs.sonTree.treeNode.getCheckedNodes();
        this.resetLeftCheckStatus();
      });
    },
    toggleNormalCheckAll() {
      const list = [];
      this.list.forEach((item) => {
        if (!item.disabled) {
          list.push(item);
        }
      });
      this.checkedList = this.checkedAll ? list : [];
      const len = this.checkedList.length;
      this.isIndeterminate = len > 0 && len < this.list.length;
    },
    /**
     * 重新设置左边整体状态
     */
    resetLeftCheckStatus() {
      this.checkedAll = this.checkedList.length === this.allowCheckList.length && this.allowCheckList.length > 0;
      this.isIndeterminate = this.checkedList.length > 0 && this.checkedList.length < this.allowCheckList.length;
    },
    /**
     * @description 关键字搜索
     * */
    handleKeywordSearch() {
      const table = this._MxFindComponentUpward(this, "EnTable");
      const { filterStore } = table;
      const { column, dataList } = filterStore;

      const dd = {
        column,
        field: column.field,
        prop: column.field,
        property: column.field,
        type: "filter",
        value: this.keywords,
        valueText: this.keywords,
        filterSelectType: "name"
      };

      const index = dataList.findIndex((itm) => (itm.type === "filter" && itm.field === column.field));
      const pa = { id: this.keywords, name: this.keywords };
      this.$emit("setResult", [pa]);
      this.resetLeftCheckStatus();
      if (index >= 0) {
        dataList.splice(index, 1, dd);
      } else {
        dataList.push(dd);
      }
      setTimeout(table.confirmFilterEvent, 50);
    },
    /**
     * @description 模糊搜索
     * */
    handleFuzzySearch() {
      // 远程搜索
      if (this.lazy) {
        this.$parent.handleRemote(this.keywords);
      } else {
        this.isTree && this.$refs.sonTree.treeNode.filter(this.keywords);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.en-tree-transfer{
  .en-transfer-panel{
    overflow: hidden;
    padding-bottom: 10px;
    .en-keyword-search{
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding: 0 10px;
      color: #aeaeae;
      cursor: pointer;
      margin-top: -10px;
    }
  }
}
</style>
