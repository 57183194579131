/*
 * @Descripttion:
 * @Author: 彭博
 * @Date: 2020-07-20 17:43:05
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-01 15:28:34
 */

import BaseService from "@/api/BaseService";

/**
 * select 相关
 */
class TransferService extends BaseService {
  pk = "";

  constructor(http) {
    super("", http);
  }

  // 部门 人员 ？
  getDeptUserTree(params) {
    return this.http.post("/getDeptUserTree", params);
  }

  // 查询人员树 ？
  bdQueryPersonDeptTree(params) {
    return this.http.post("/bdQueryPersonDeptTree", params);
  }

  // 会计科目
  querySubjectAll(params) {
    return this.http.post("/querySubjectAll", params);
  }

  // 纳税主体
  queryTaxDiclistByType(params) {
    return this.http.post("/queryTaxDiclistByType", params);
  }

  // isSysManager ?? 未知
  queryObjectTreeExtAccess(params) {
    return this.http.post("/queryObjectTreeExtAccess", params);
  }

  // 查询基础数据 异步
  bdQueryObjectTree(params) {
    return this.http.post("/bdQueryObjectTree", params);
  }

  // 未知
  queryObjectTreeExt(params) {
    return this.http.post("/queryObjectTreeExt", params);
  }

  // 自定义项查询
  mdQueryLable(params) {
    return this.http.post("/mdQueryLable", params);
  }
}

export const transferService = new TransferService();
