var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-checkbox-group",
    {
      attrs: { max: _vm.$parent.config.isMulti ? 999 : 1 },
      model: {
        value: _vm.checkedValue,
        callback: function ($$v) {
          _vm.checkedValue = $$v
        },
        expression: "checkedValue",
      },
    },
    _vm._l(_vm.showList, function (item, index) {
      return _c(
        "el-checkbox",
        {
          key: index,
          staticClass: "en-transfer-panel-item",
          attrs: { label: item, disabled: item.disabled },
        },
        [
          _vm._t(
            "left-content",
            function () {
              return [_vm._v(" " + _vm._s(item[_vm.$parent.props.label]) + " ")]
            },
            {
              node: Object.assign(item, {
                label: item[_vm.$parent.props.label],
              }),
            }
          ),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }