/*
 * @Descripttion:
 * @Author: 彭博
 * @Date: 2020-07-22 11:08:31
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-07-01 15:28:44
 */

import cityData from "cityData";
import Store from "@/store";
import { base } from "@/assets/js/bank";

// city Module
// const cityModule = async() => import("@/assets/js/cityData");

/**
 * 根据业务逻辑 匹配对应请求接口  或 静态数据
 * @param {Object} dependData 用于逻辑判断参数
 * @returns: reqStr:String --> 请求接口名称 此数据不为真时 取静态数据dataList 不调接口
 * @returns: reqData:Object --> 请求接口参数
 * @returns: dataList:Array --> 静态数据
 * @returns: treeParams:Object --> 数据默认 value:id ,label:name ,children:children  否则修改指定值
 */
export const matchConfig = (dependData = {}) => {
  const {
    areaObjType: type, field, dataSource, commType, areaObjTypeId, reqUrl
  } = dependData;
  const userInfo = Store.getters.userInfo || {};
  let reqStr = "";
  let reqData = null;
  let dataList = null; // 取静态数据时使用
  let hasChildren = true;
  let treeParams = {
    label: "name",
    value: "id",
    children: "children"
  };

  // 支持几种通用情况
  if (commType === 1 || type === "002") {
    // 基础数据人员树
    // reqStr = "getDeptUserTree";
    reqStr = "bdQueryPersonDeptTree";
    reqData = { id: "0", ids: dependData.ids || "" };
  } else if (commType === 2 || type === "001") {
    // 组织机构树
    reqStr = userInfo.isSysManager === "000" ? "bdQueryObjectTree" : "queryObjectTreeExt";
    reqData = {
      objectTypeId: areaObjTypeId || "", ids: dependData.ids || "", deptType: dependData.deptType || "", objectDataType: "001", id: 0
    };
  } else if (type === "014") {
    // 会计期间
    reqStr = "bdOperatorList";
    reqData = { pageSize: 10000, pageNo: 1, objectTypeId: areaObjTypeId };
    hasChildren = true;
  } else if (type === "016") {
    // 会计科目
    reqStr = "querySubjectAll";
    reqData = {};
    hasChildren = false;
  } else if (field?.toLowerCase() === "bank") {
    // 兼容银行卡信息
    dataList = base.bank || [];
    hasChildren = false;
  } else if (field?.toLowerCase() === "city") {
    // 城市
    dataList = base.city || [];
    hasChildren = false;
    treeParams.value = "code";
  } else if (field?.toLowerCase() === "bankcode") {
    // 支持不了支行，选项太多了
  } else if (type === "100") {
    // 纳税主体的字段
    reqStr = "queryTaxDiclistByType";
    reqData = { type: dependData.areaObjTypeId };
    hasChildren = false;
  } else if (field === "dataSource" && dataSource === 3) {
    // 主题数据源
    dataList = typeof dependData.selContent === "string" ? JSON.parse(dependData.selContent) : dependData.selContent;
    hasChildren = false;
  } else if (dependData.areaObjTypeId || type) {
    // 非人员基础数据
    reqData = { objectTypeId: dependData.areaObjTypeId, ids: dependData.ids || "", deptType: dependData.deptType || "" };
    // 接口 区分 管理端和用户端
    if (userInfo.isSysManager === "000") {
      reqStr = "bdQueryObjectTree";
      reqData.objectDataType = type;
    } else {
      reqStr = "queryObjectTreeExt";
      reqData.objectType = type;
    }
    // type为空，表示自定义类型单选
  } else if (dataSource === 10) {
    // 查找静态城市数据
    // 改造成tree树结构数据
    // const cityData = await cityModule();
    dataList = cityData; // children --> ns value --> ec  name --> en
    treeParams = {
      label: "en",
      value: "ec",
      children: "ns"
    };
  } else if (dataSource === 11) {
    // 11表示直接使用前面传进来的数据（dataList），格式跟请求的数据一致
    dataList = dependData.dataList || [];
  } else {
    const {
      dataType,
      dataTypeId,
      areaObjTypeId,
      templateId
    } = dependData;
    reqStr = "mdQueryLable";
    reqData = {
      templateId: dataType === 11 || dataType === 12 ? dataTypeId : areaObjTypeId || templateId || "",
      field: dependData.field,
      businessType: dependData.businessType,
      billStatusType: dependData.billStatusType
    };
  }
  if (reqUrl) reqStr = reqUrl;
  return {
    reqStr,
    reqData,
    dataList,
    treeParams,
    hasChildren
  };
};
