var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("en-tree", {
    ref: "tree",
    attrs: {
      "default-expanded-keys": [],
      "default-checked-keys": _vm.defaultCheckedKeys,
      data: _vm.$parent.list,
      props: _vm.$parent.props,
      "show-checkbox": "",
      "node-key": _vm.$parent.props.value,
      filterable: !_vm.$parent.config.hideSearch,
      "check-limit": _vm.$parent.config.isMulti ? 0 : 1,
      lazy: _vm.$parent.lazy,
      "page-size": _vm.$parent.lazy ? _vm.$parent.config.pageSize : 0,
      load: _vm.$parent.load,
    },
    on: { check: _vm.changePanel },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ node, data }) {
            return [
              _vm._t(
                "left-content",
                function () {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "el-tree-node__label",
                        style: { color: node.color },
                        attrs: { title: node.title },
                      },
                      [_vm._v(_vm._s(node.label))]
                    ),
                  ]
                },
                { data: data, node: node }
              ),
            ]
          },
        },
        {
          key: "assist",
          fn: function ({ node, data }) {
            return [
              _vm._t(
                "left-assist",
                function () {
                  return [
                    _c("span", { staticClass: "en-node-assist" }, [
                      _vm._v(_vm._s(node.label)),
                    ]),
                  ]
                },
                { data: data, node: node }
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }