<!--
 * @Descripttion: 带数据请求 穿梭框
 * @Author: 彭博
 * @Date: 2020-07-22 09:36:41
 * @LastEditors: linshanfeng
 * @LastEditTime: 2023-12-19 11:19:37
-->
<template>
  <div class="transfer-data" v-en-loading="loading">
    <div class="transfer-header">
      <div class="header-left">未选用户<span class="header-count">({{resultList.length}}/{{allLen || dataList.length}})</span></div>
      <div class="header-right">已选用户<span class="header-count">({{resultList.length}})</span></div>
    </div>
    <!-- :class="['transfer-data',{'hide-right': configParams.hideRight}]" -->
    <div class="transdata">
      <only-left v-if="configParams.hideRight"
               :type="configParams.modeType"
               class="en-transfer hide-right"
               v-model="resultList"
               :config="configParams"
               :list="dataList"
               :props="defaultProps"
               :lazy="lazy"
               :height="height"
               :page-size="lazy ? configParams.pageSize : 0"
               :load="loadNode"
      >
      <template #left-content="{ data, node }">
        <slot name="left-content" :data="data" :node="node"></slot>
      </template>
      <template #left-title>
        <slot name="left-title"></slot>
      </template>
      <template #left-assist="{ data, node }">
        <slot name="left-assist" :data="data" :node="node"></slot>
      </template>
    </only-left>
    <!-- :single-select="configParams.singleSelect"  新版本已废除？？-->
    <!-- :select-child="configParams.selectChild" 子节点 disabled 当前字段废除 -->
    <en-transfer :type="configParams.modeType"
                 v-else
                 :show-search="configParams.hideSearch ? false : true"
                 :list="dataList"
                 v-model="resultList"
                 :exclude="configParams.exclude"
                 :props="defaultProps"
                 :multiple="configParams.isMulti ? 0 : 1"
                 :show-head="configParams.showHead"
                 :reset-left="configParams.resetLeft"
                 :can-sort="configParams.canSort"
                 :show-assist="configParams.showAssist"
                 :show-title="configParams.showTitle"
                 :lazy="lazy"
                 :resetLeft="false"
                 :allLen="allLen"
                 :load="loadNode"
                 @changeAllLength="getAll"
                 :self-define="configParams.selfDefine"
                 :pageSize="configParams.pageSize"
                 class="en-transfer en-transfer-all"
                 :img-path="configParams.imgPath">
      <template #left-title>
        <slot name="left-title"></slot>
      </template>
      <template #right-title>
        <slot name="right-title"></slot>
      </template>
      <template #left-content="{ item, data, node }">
        <slot name="left-content" :item="item" :data="data" :node="node"></slot>
      </template>
      <template #left-assist="{ data, node }">
        <slot name="left-assist" :data="data" :node="node"></slot>
      </template>
      <template #right-content="{ item }">
        <slot name="right-content" :item="item"></slot>
      </template>
    </en-transfer>
    </div>
  </div>
</template>

<script>
import { request } from "en-js";
import { cloneDeep } from "lodash";
import { matchConfig } from "./matchRequestConfig";
import { transferService } from "./TransferService";
import OnlyLeft from "./onlyLeft";

export default {
  name: "EnTransferData",
  componentName: "EnTransferData",
  model: {
    prop: "modelList",
    event: "selected"
  },
  props: {
    config: {
      type: Object,
      default: () => ({
        // // 组件数据配置
        // // dependData 必填 请求数据依赖项 参考老版本配置
        // dependData: {
        //   dataSource: 11, // 10 城市数据请求    11自定义数据 配合 dataList使用
        //   // field: "city",
        //   dataList: [
        //     { id: 1, name: "自定义1", hasChildren: "001" },
        //     { id: 2, name: "自定义2", hasChildren: "001" },
        //     { id: 3, name: "自定义3", hasChildren: "000" },
        //     { id: 4, name: "自定义4", hasChildren: "001" }
        //   ]
        //   // areaObjType: "002"
        //   // templateId: ""
        // },
        // // 组件基础配置
        // // hideSearch: false, // 左侧面板是否需要显示搜索框
        // // showTitle: true, //是否显示标题区域
        // // choiceRule: false, // 是否勾选父项
        // // isMulti: true, // 是否多选
        // // showHead: true, // 右侧选中的数据是否需要显示头像
        // // exclude: true, // 是否需要显示排除按钮
        // // imgPath  // 数据的头像承载字段
        // // canSort
        // filterObj: {
        //   type: "003"
        // },
        // title: ["左侧的数据面板", "右侧的数据面板"], // 穿梭框标题
        // showTitle: true, // 显示标题
        // placeholder: "输入xxxx", // 输入框默认placeholder
        modeType: "tree", // 左侧结构 tree树形  normal 平行
        hideRight: false, // 是否显示 穿梭框  false只显示左边部分
        keywordSearch: false // 是否支持关键字搜素 v4.9.7
      })
    },
    modelList: {
      type: Array,
      default: () => []
    },
    height: Number,
    lazy: {
      type: Boolean,
      default: true
    }
  },
  components: {
    OnlyLeft
  },
  data() {
    return {
      allLen: 0,
      loading: false,
      resolveFn: null,
      configParams: {
        // 组件基础配置
        pageNo: 0,
        pageSize: 20,
        hideSearch: false, // 左侧面板是否需要显示搜索框
        choiceRule: true, // 是否勾选父项
        showTitle: true,
        isMulti: true, // 是否多选
        showHead: true, // 右侧选中的数据是否需要显示头像
        resetLeft: true, // 向右选择后左面板是否需要刷新
        exclude: true, // 是否需要显示排除按钮
        canSort: false // 是否需要筛选
        // imgPath  // 数据的头像承载字段
      },
      defaultProps: {
        label: "name",
        children: "children",
        value: "id",
        isLeaf: "leaf"
      },
      dataList: [],
      // resultList: this.modelList,
      treeTransfer: {
        type: "tree",
        filter: true,
        showHead: false,
        selfIcon: false
      }
    };
  },
  mounted() {
    console.log("穿梭框resultList");
    console.log(this.resultList);
    console.log("穿梭框modelList");
    console.log(this.modelList);
  },
  computed: {
    resultList: {
      get() {
        return this.modelList;
      },
      set(value) {
        this.$emit("selected", value);
      }
    }
  },
  watch: {
    // resultList(value) {
    //   console.log("selected value =>", value);
    //   this.$emit("selected", value);
    // },
    "config.dependData": {
      deep: true,
      immediate: true,
      async handler(value) {
        console.log("transfer config changed", value);
        this.configParams.pageNo = 1;
        this.configParams.choiceRule = value.choiceRule ?? true;
        this.configParams.dependData = cloneDeep(value);
        // 监听 dependData 改变 重新获取数据
        await this.initTreeData();
      }
    }
  },
  methods: {
    // 判断当前item 是否disabled
    itemIsDisabled(item = {}) {
      // 配置了不允许勾选父项
      if (!this.configParams.choiceRule) {
        return item[this.defaultProps.children]?.length || String(item.parentId) === "0" || item.hasChildren === "000";
      }
      return false;
    },
    filterDataList(list = []) {
      const filterObj = this.configParams.filterObj;
      if (!filterObj) return Array.isArray(list) ? list : list.userDepatement;
      return list.filter((item) => this.matchKey(item, filterObj));
    },
    // 遍历 filter规则 有任意条件不符合规则  返回false
    matchKey(obj = {}, keyObj) {
      let status = true;
      if (this.configParams.modeType === "tree" && obj.hasChildren !== "000") {
        Object.keys(keyObj)
          .forEach((key) => {
            if (obj[key] !== keyObj[key]) status = false;
          });
      }
      return status;
    },
    // 格式化列表
    formatList(list = [], bool) {
      list = this.filterDataList(list);
      return this.mapFill(list, bool);
    },
    getAll(value) {
      console.log(value, "xxxxx");
      if (!value.type) {
        this.allLen = value.num;
      } else if (value.type === "jia") {
        this.allLen += value.num;
      } else {
        this.allLen -= value.num;
      }
    },
    // 填充 组件所需属性
    // 塞入 hasChildren = 001
    mapFill(list = [], bool) {
      return list.map((opt) => {
        const obj = {
          disabled: this.itemIsDisabled(opt),
          // !bool 为真表示叶子
          leaf: (!bool || opt.hasChildren !== "000") && !opt[this.defaultProps.children]?.length,
          [this.defaultProps.children]: this.formatList(opt[this.defaultProps.children], bool)
        };
        // bool === true  没有children 用于树判断
        if (!bool) obj.hasChildren = "001";
        return Object.assign(opt, obj);
      });
    },
    // 请求tree树 数据
    @request(true, "loading")
    async initTreeData() {
      this.dataList = await this.getTransferData();
      this.allLen = this.dataList.length;
    },
    // 获取数据
    async getTransferData(parentId = null, text = "") {
      console.log("getTransferData");
      const configParams = this.configParams;
      if (this.configParams.dependData?.requestService) {
        return this.formatList(await this.customRequest(parentId, text), true);
      }
      const config = matchConfig(configParams.dependData);
      console.log(config);
      !this.configParams.defaultProps && (this.defaultProps = Object.assign(this.defaultProps, config.treeParams)); // 配置参数未设置默认参数时 设置默认展示key
      if (!config.reqStr) {
        // this.lazy = false;
        return this.formatList(config.dataList || [], config.hasChildren);
      }
      const params = { ...config.reqData };
      if (this.config.reqData) {
        Object.assign(params, this.config.reqData);
      }
      configParams.pageSize && (params.pageSize = configParams.pageSize);
      configParams.pageNo && (params.pageNo = configParams.pageNo);
      parentId && (params.id = parentId);
      text && (params[this.defaultProps.label] = text);
      const res = await transferService[config.reqStr](params);
      // this.modeType = "normal";
      return this.formatList(res || [], config.hasChildren);
    },
    // 加载子树
    async loadNode(node, resolve, more, text = "") {
      /* eslint-disable */
      let data = [];
      // resolve({
      //   datas: [],
      //   pageNo: 1,
      //   totol: 1000
      // });
      // return;
      const {parent, childNodes, pageSize} = node;
      const len = Math.floor(childNodes.length / pageSize)||0;
      if (parent || more || text) {
        const parentId = parent ? node.data?.id : null;
        this.lazy && (this.configParams.pageNo = len + 1); // 开启分页  计算当前页数
        const options = await this.getTransferData(parentId,text);
        console.log(this.allLen, options.length,'11111');
        
        console.log(this.allLen);
        if (this.lazy) {
          data = {
            total: options.length === this.configParams.pageSize ? 600 : 0,
            pageNo: node.pageNo + 1,
            datas: options
          };
          if(text){
            data=data.datas
          }
         
        } else {
          data = options;
        }
        this.allLen = options.length ? this.allLen + options.length : this.allLen + 0;
      }
      resolve(data);
    },
    async customRequest(parentId,searchText) {
      const { requestService, requestParams, requestFn } = this.configParams.dependData;
      let reqParams = cloneDeep(requestParams);
      this.configParams.pageNo && (reqParams.pageNo = this.configParams.pageNo);
      parentId && (reqParams.id = parentId);
      reqParams.name=searchText
      let result;
      const request = requestService[requestFn];
      if (request) {
        result = await requestService[requestFn](reqParams);
        // result = await request(requestParams);
      } else {
        result = await requestService.post(requestFn, reqParams);
      }
      return result;
    },
    //远程搜索
    async handleRemote(txt){
      this.dataList = await this.getTransferData(null, txt);
    }
  },
  created() {
    Object.assign(this.configParams, this.config); // 合并config
    this.config.defaultProps && Object.assign(this.defaultProps, this.config.defaultProps);

    // this.initTreeData();
  }
};
</script>

<style scope lang="scss">
  .transfer-data {
    width: 100%;
    .transfer-header{
      display: flex;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
     .header-left{
      height: 50px;
      padding-left:20px;
      line-height: 50px;
      background: #F2F5F8;
      border-radius: 4px 0px 0px 0px;
      border-right: 1px solid #E8ECF2;
      width:50%;
      .header-count{
        padding-left: 11px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #91A1B7;
      }
    }
    .header-right{
      height: 50px;
      line-height: 50px;
      padding-left:20px;
      background: #F2F5F8;
      border-radius: 4px 0px 0px 0px;
      width:50%;
      .header-count{
        padding-left: 11px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #91A1B7;
      }
    }
    }

    
  }

  .en-transfer {
    text-align: left;
    &.hide-right {
      > .en-transfer-panel {
        &:first-child {
          width: 100%;
        }
      }
    }

  }
.transdata{
  height:calc(100% - 50px);
}



</style>
<style>
.en-transfer {
  height: 100%;
  max-height: 60vh;
}

.transfer-data .en-transfer .en-transfer-panel{
  border-radius:0px;
}
.transfer-data .en-transfer .en-transfer-panel{
  border-top:none;
}
.transfer-data .en-transfer.hide-right .en-transfer-panel{
  border-radius:0px 0px 4px 4px;
}
.transfer-data .en-transfer.en-transfer-all .en-transfer-panel:last-child{
  border-radius:0px 0px 4px 0px;
}

.transfer-data .en-transfer.en-transfer-all .en-transfer-panel:first-child{
  border-radius:0px 0px 0px 4px;
}
</style>
