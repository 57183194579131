<!--
 * @Descripttion: 树形
 * @Author: 彭博
 * @Date: 2020-07-23 09:28:59
 * @LastEditors: 彭博
 * @LastEditTime: 2020-08-19 09:52:53
-->
<template>
  <en-tree
    ref="tree"
    :default-expanded-keys="[]"
    :default-checked-keys="defaultCheckedKeys"
    :data="$parent.list"
    :props="$parent.props"
    show-checkbox
    :node-key="$parent.props.value"
    :filterable="!$parent.config.hideSearch"
    :check-limit="$parent.config.isMulti ? 0 : 1"
    :lazy="$parent.lazy"
    :page-size="$parent.lazy ? $parent.config.pageSize : 0"
    :load="$parent.load"
    @check="changePanel"
  >
    <template v-slot="{ node, data }">
      <slot name="left-content" :data="data" :node="node">
        <span class="el-tree-node__label" :title="node.title" :style="{ color: node.color }">{{ node.label }}</span>
      </slot>
    </template>
    <template #assist="{ node, data }">
      <slot name="left-assist" :data="data" :node="node">
        <span class="en-node-assist">{{ node.label }}</span>
      </slot>
    </template>
  </en-tree>
</template>

<script>
export default {
  model: {
    prop: "checkedList",
    event: "throwCheckedList"
  },
  data() {
    return {
      defaultCheckedKeys: []
    };
  },
  props: {
    checkedList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    treeNode() {
      return this.$refs.tree;
    }
  },
  mounted() {
    this.defaultCheckedKeys = this.checkedList.map((item) => item.id);
  },
  methods: {
    changePanel() {
      this.$emit("throwCheckedList", this.$refs.tree.getCheckedNodes());
    }
  }
};
</script>

<style></style>
